import { useCustomerContext } from '@/hooks/useCustomerContext';
import { Typography } from 'uibook';
import { IconCheckBadge } from 'uibook-icons/solid/IconCheckBadge';
import { IconExclaimationCircleFilled } from 'uibook-icons/solid/IconExclaimationCircleFilled';

type BannerContentProps = {
  formattedAmount: string | null;
  shortVersion?: boolean;
  inArrears?: boolean;
};

export const BannerContent = ({
  formattedAmount,
  shortVersion = false,
  inArrears,
}: BannerContentProps) => {
  const { itemInArrearsPaymentSlug } = useCustomerContext();

  if (inArrears) {
    return (
      <div className="flex items-start" data-testid="in-arrears-banner">
        <IconExclaimationCircleFilled className="text-error-500 size-5 shrink-0" />
        <Typography className="ml-2 self-center text-left" variant="body2">
          {'Payments overdue. '}
          <a
            className="type-body2 text-blue-500 hover:underline"
            href={itemInArrearsPaymentSlug}
            title="Make overdue payment"
          >
            Pay now
          </a>
        </Typography>
      </div>
    );
  }

  if (formattedAmount) {
    return (
      <div className="flex items-start" data-testid="pre-approved-amount-banner">
        <IconCheckBadge className="size-6 shrink-0 text-blue-300" />
        <Typography className="ml-2 self-center text-left" variant="body2">
          {shortVersion ? 'Pre-approved: ' : 'Available pre-approved limit: '}
          <span className="font-bold">{formattedAmount}/mo</span>
        </Typography>
      </div>
    );
  }

  return null;
};
