/** This is the same structured data that is used on raylo.com */
export const DEFAULT_STRUCTURED_DATA = {
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': 'Organization',
      name: 'Raylo Group Limited',
      foundingDate: '2018',
      url: 'https://www.raylo.com/',
      logo: 'https://raylo.imgix.net/raylo-logo-square.png',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '5 New Street Square',
        addressLocality: 'London',
        addressRegion: 'London',
        postalCode: 'EC4A 3TW',
      },
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+44 (0)333 050 9880',
        contactType: 'Customer service',
        areaServed: 'UK',
      },
    },
    {
      '@type': 'WebSite',
      url: 'https://www.raylo.com/',
      /** Inform search engines that a search action can be triggered */
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://www.raylo.com/products?q={search_term_string}',
        'query-input': 'required name=search_term_string',
      },
    },
  ],
};

export const RETURN_POLICY_STRUCTURED_DATA = {
  hasMerchantReturnPolicy: {
    '@type': 'MerchantReturnPolicy',
    applicableCountry: 'GB',
    returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
    merchantReturnDays: 14,
    returnMethod: 'https://schema.org/ReturnByMail',
    returnFees: 'https://schema.org/FreeReturn',
  },
};
